var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加监控设备",
            visible: _vm.dialogVisible,
            width: "450px",
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "350px" },
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_name"),
                    prop: "monitorEquipmentName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 50, placeholder: "请输入设备名称" },
                    model: {
                      value: _vm.form.monitorEquipmentName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "monitorEquipmentName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.monitorEquipmentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "IP地址", prop: "ipAddress" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 30, placeholder: "请输入IP地址" },
                    model: {
                      value: _vm.form.ipAddress,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "ipAddress",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.ipAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "端口号", prop: "ipPort" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入端口号" },
                    model: {
                      value: _vm.form.ipPort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ipPort", $$v)
                      },
                      expression: "form.ipPort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "视频端口号", prop: "monitorPort" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入视频端口号" },
                    model: {
                      value: _vm.form.monitorPort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "monitorPort", $$v)
                      },
                      expression: "form.monitorPort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 30, placeholder: "请输入用户名" },
                    model: {
                      value: _vm.form.account,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "account",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 30, placeholder: "请输入密码" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }