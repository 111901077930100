<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-row>
        <el-col :span="14">
          <h2 class="title">
            <div class="title_icon"></div>
            {{ $route.query.slaveRelationName }}配置
          </h2>
        </el-col>
        <el-col :span="1" :offset="9">
          <div style="float: right">
            <el-button @click="$router.go(-1)" type="primary" style="float: right">返回</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName2" type="card" @tab-click="handleClick" class="tab-wrap">
        <el-tab-pane v-if="parkAccessType == 3" label="业务参数配置" name="first">
          <parameters :authority="authority.tabs['parameters']" ref="parameters"></parameters>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['videMonitor']" label="视频监控管理" name="second">
          <vide-monitor :authority="authority.tabs['videMonitor']" ref="videMonitor"></vide-monitor>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['channel']" label="通道配置" name="third">
          <channel :authority="authority.tabs['channel']" ref="channel"></channel>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['gate']" label="开闸方式配置" name="fourth">
          <gate :authority="authority.tabs['gate']" ref="gate"></gate>
        </el-tab-pane>
        <el-tab-pane v-if="0" label="计费规则管理" name="five">
          <calculate :authority="authority.tabs['calculate']" ref="calculate"></calculate>
        </el-tab-pane>
        <el-tab-pane
          v-if="
            authority.tabs[parkAccessType == 3 ? 'lbParkCarMange' : 'parkCarMange'] &&
            parkAccessType == 3
          "
          label="车辆管理"
          name="six"
        >
          <cars-manage
            :authority="authority.tabs[parkAccessType == 3 ? 'lbParkCarMange' : 'parkCarMange']"
            :details="details"
            ref="cars"
          ></cars-manage>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import parameters from "./parkSetChilds/secondParameters";
import channel from "./parkSetChilds/secondChannel";
import videMonitor from "./parkSetChilds/secondVideMonitor";
import gate from "./parkSetChilds/secondGate";
import calculate from "./parkSetChilds/secondCalculateManageConf";
import carsManage from "./parkSetChilds/CarsManage/index";
export default {
  name: "hello",
  data() {
    return {
      activeName2: "first",
      details: this.$route.query,
      authority: this.$setAuthority("secondParkConfig"),
      parkAccessType: this.$route.query.parkAccessType,
    };
  },
  watch: {
    authority: {
      handler(ne, ol) {
        let key = this.parkAccessType == 3 ? Object.keys(ne.tabs)[1] : Object.keys(ne.tabs)[3];
        // if (Object.keys(ne.tabs)[0] == "video" && this.$route.query.parkEquipmentType == 4) {
        //   key = Object.keys(ne.tabs)[1];
        // }
        this.$nextTick(() => {
          switch (key) {
            case "parameters":
              this.$refs.parameters.init();
              break;
            case "videMonitor":
              this.activeName2 = "second";
              this.$refs.videMonitor.init();
              break;
            case "channel":
              this.activeName2 = "third";
              this.$refs.channel.init();
              break;
            case "gate":
              this.activeName2 = "fourth";
              this.$refs.gate.init();
              break;
            case "calculate":
              this.activeName2 = "five";
              this.$refs.calculate.init();
              break;
            case "parkCarMange":
              this.activeName2 = "six";
              this.$refs.cars.init();
              break;
            default:
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "first":
          this.$refs.parameters.init();
          break;
        case "second":
          this.$refs.videMonitor.init();
          break;
        case "third":
          this.$refs.channel.init();
          break;
        case "fourth":
          this.$refs.gate.init();
          break;
        case "five":
          this.$refs.calculate.init();
          break;
        case "six":
          this.$refs.cars.init();
          break;
        default:
      }
    },
  },
  components: {
    parameters,
    videMonitor,
    channel,
    gate,
    calculate,
    carsManage,
  },
  created() {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 42px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    float: right;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .btnWrapper {
    text-align: left;
    margin-top: 28px;
    float: left;
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.transfer {
  margin: 0 auto;
}

.button {
  margin-bottom: 15px;
  text-align: right;
}

.ruleDetail {
  width: 500px;
  height: 150px;
  overflow: auto;
  border: 1px solid #bfcbd9;
  border-radius: 5px;
  color: #1f2d3d;
}

.feeDis {
  margin-left: 20px;
}

.berth-sort {
  margin-top: 10px;
}
.tab-wrap >>> .el-tabs__header {
  background: #FFFFFF;
  margin-bottom: 0;
  .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }
  .el-tabs__item {
    height: 45px;
    line-height: 45px;
  }
}
</style>
<style>
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
