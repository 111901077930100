<template>
    <div>
        <el-dialog
          title="添加监控设备"
          :visible.sync="dialogVisible"
          width="450px"
          :close-on-click-modal='false'
          custom-class='dialog'
		  @close='resetForm'
         >
          <el-form label-position="right" label-width="100px" :model="form" style="width: 350px;" :rules="rules" ref="form">
			  <el-form-item :label="$t('searchModule.Equipment_name')" prop="monitorEquipmentName" >
				<el-input :maxlength='50' v-model.trim="form.monitorEquipmentName" placeholder="请输入设备名称" ></el-input>
			  </el-form-item>
			  <el-form-item label="IP地址" prop="ipAddress">
				<el-input :maxlength='30' v-model.trim="form.ipAddress" placeholder="请输入IP地址" ></el-input>
			  </el-form-item>
			  <el-form-item label="端口号" prop="ipPort" >
				<el-input :maxlength='20'  v-model="form.ipPort" placeholder="请输入端口号" ></el-input>
			  </el-form-item>
			  <el-form-item label="视频端口号" prop="monitorPort" >
				<el-input :maxlength='20' v-model="form.monitorPort" placeholder="请输入视频端口号" ></el-input>
			  </el-form-item>
			   <el-form-item label="用户名" prop="account" >
				<el-input :maxlength='30'  v-model.trim="form.account" placeholder="请输入用户名" ></el-input>
			  </el-form-item>
			  <el-form-item label="密码" prop="password" >
				<el-input :maxlength='30' v-model.trim="form.password" placeholder="请输入密码" ></el-input>
			  </el-form-item>
         </el-form>
            <div slot="footer">
                <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
                <el-button @click="resetForm">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
   data () {
       // IP地址校验
       let checkIP = (rule, value, callback) => {
           if (!value) {
               return callback();
           }
           if (!/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(value)) {
               return callback('ip地址格式不正确！')
           }

           return callback();
       }

	   // 端口号
       let checkPort = (rule, value, callback) => {
           if (!value) {
               return callback();
           }
           if (!/^[\d]+$/.test(value)) {
               return callback('端口号必须为数字！')
           }
           if (value > 65535) {
                callback(new Error('端口号应小于65535!'));
            }
           return callback();
       }

	   // 视频端口号
       let monitorPort = (rule, value, callback) => {
         if (!value) {
           return callback();
         }
         if (!/^[\d]+$/.test(value)) {
           return callback('视频端口号必须为数字！')
         }
         if (value > 65535) {
           callback(new Error('视频端口号应小于65535!'));
         }
         return callback();
       }
       return {
           dialogVisible: false,
		   saveLoading: false,
           form: {
				monitorEquipmentName: '',
				ipAddress: '',
				ipPort: '',
				monitorPort: '',
				account: '',
				password: ''
           },
           rules: {
               monitorEquipmentName: [
				   {
					   required: true,
					   message: '设备名称必填',
					   trigger: 'blur'
                  }
			   ],
               ipAddress: [
					{
					   required: true,
					   message: 'IP地址必填',
					   trigger: 'blur'
				   },
				   {
					   validator: checkIP, trigger: 'blur'
				   }
			   ],
               ipPort: [
				   {
					   required: true,
					   message: '端口号必填',
					   trigger: 'blur'
				   },
				   {
					   validator: checkPort, trigger: 'blur'
				   }
			   ],
               monitorPort: [
					{
					   required: true,
					   message: '视频端口号必填',
					   trigger: 'blur'
				   },
				   {
					   validator: monitorPort, trigger: 'blur'
				   }
			   ],
               account: [
				   {
					   required: true,
					   message: '用户名必填',
					   trigger: 'blur'
				   }
			   ],
               password: [
					{
					   required: true,
					   message: '密码必填',
					   trigger: 'blur'
				   }
			   ]
		   }
       }
   },
   methods: {
       // 打开窗口
       open () {
           this.dialogVisible = true;
           for (let i in this.form) {
               this.form[i] = '';
           }
       },


	   // 保存
       save () {
           this.$refs.form.validate(valid => {
               if (valid) {
			       this.saveLoading = true;
                   this.$axios.post('/acb/2.0/bacb/monitorEquipment/add', {
                        data: {
                            parkId: this.$route.query.parkId,
                            ...this.form
                        }
                    }).then(res => {
					    this.saveLoading = false;
                        if (res.state == 0) {
                            this.dialogVisible = false;
                            this.$emit('searchData');
                        } else {
                            this.$alert(res.desc, this.$t('pop_up.Tips'), {
                            confirmButtonText: this.$t('pop_up.Determine')
                            })
                        }
                    }).catch(() => {
						this.saveLoading = false;
					})
               }
           })
       },

	   // 取消
	   resetForm () {
		  this.dialogVisible = false;
		  this.$refs.form.resetFields();
	   }
   }
}
</script>
<style>
    .formWidth{
        width:221.5px;
    }

</style>

