<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        出入场配置
      </h2>
      <el-form ref="form" :rules="rules" :model="form" label-width="240px" style="padding: 0">
        <el-row type="flex" justify="center">
          <el-col v-if="0" :span="10">
            <el-form-item label="无牌车是否允许入场" prop="noPlateEntry">
              <el-radio-group v-model="form.noPlateEntry">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="车位满时是否允许月租车入场" prop="monthlyCarRentalEntry">
              <el-radio-group v-model="form.monthlyCarRentalEntry">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              style="display: inline-block"
              label="支持相似车牌出入场匹配"
              prop="similarPlateMatch"
            >
              <el-radio-group v-model="form.similarPlateMatch" @change="form.defaultProvince = ''">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              style="display: inline-block; margin-left: 10px; width: 190px"
              label-width="90px"
              label="默认省份"
              prop="defaultProvince"
              v-if="form.similarPlateMatch === 1"
            >
              <el-select v-model="form.defaultProvince" placeholder="请选择">
                <el-option
                  :label="ite.value"
                  :value="ite.key"
                  :key="index"
                  v-for="(ite, index) in mrsfList"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="14">
            <el-form-item>
              <el-button type="danger" plain @click="updateData()" :loading="updateLoading" v-if="0"
                >保存失败</el-button
              >
              <el-button
                type="primary"
                plain
                @click="updateData()"
                :loading="updateLoading"
                v-if="0"
                >保存成功</el-button
              >
              <el-button
                type="primary"
                @click="save"
                :loading="saveLoading"
                v-if="authority.button.addSync"
                >{{ $t('button.preservation') }}</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
// import addBerth from './add'
// import editBerth from './edit'
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  props: ["authority"],
  name: "businessIndex",
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      form: {
        parkBusiParamId: "",
        noPlateEntry: "",
        monthlyCarRentalEntry: "",
        // innerPayFreeLeaveTime: '',
        similarPlateMatch: 1,
        defaultProvince: "", // 默认省份
        policeCarFree: "",
      },
      rules: {
        noPlateEntry: [{ required: true, message: "请选择是否允许入场", trigger: "change" }],
        monthlyCarRentalEntry: [{ required: true, message: "请选择月租车入场", trigger: "change" }],
        // innerPayFreeLeaveTime: [
        // 	{ required: true, message: '请输入免费离场时间', trigger: 'blur' },
        // ],
        similarPlateMatch: [
          {
            required: true,
            message: "请选择车牌出入场匹配",
            trigger: "change",
          },
        ],
        // 默认省份
        defaultProvince: [
          {
            required: true,
            message: "请选择默认省份",
            trigger: "change",
          },
        ],
        policeCarFree: [
          {
            required: true,
            message: "请选择军警车是否计费",
            trigger: "change",
          },
        ],
        // childParkId: [
        // 	{ required: true, message: '请选择缺失收费区域偏向', trigger: 'change' }
        // ],
      },
      // 记录缺失数据
      parkList: [],
      updateLoading: false,
      saveLoading: false,
      mrsfList: [
        {
          key: 1,
          value: "京",
        },
        {
          key: 2,
          value: "津",
        },
        {
          key: 3,
          value: "沪",
        },
        {
          key: 4,
          value: "渝",
        },
        {
          key: 5,
          value: "冀",
        },
        {
          key: 6,
          value: "晋",
        },
        {
          key: 7,
          value: "辽",
        },
        {
          key: 8,
          value: "黑",
        },
        {
          key: 9,
          value: "苏",
        },
        {
          key: 10,
          value: "浙",
        },
        {
          key: 11,
          value: "皖",
        },
        {
          key: 12,
          value: "闽",
        },
        {
          key: 13,
          value: "赣",
        },
        {
          key: 14,
          value: "鲁",
        },
        {
          key: 15,
          value: "豫",
        },
        {
          key: 16,
          value: "鄂",
        },
        {
          key: 17,
          value: "湘",
        },
        {
          key: 18,
          value: "粤",
        },
        {
          key: 19,
          value: "琼",
        },
        {
          key: 20,
          value: "川",
        },
        {
          key: 21,
          value: "贵",
        },
        {
          key: 22,
          value: "云",
        },
        {
          key: 23,
          value: "陕",
        },
        {
          key: 24,
          value: "甘",
        },
        {
          key: 25,
          value: "青",
        },
        {
          key: 26,
          value: "藏",
        },
        {
          key: 27,
          value: "桂",
        },
        {
          key: 28,
          value: "蒙",
        },
        {
          key: 29,
          value: "宁",
        },
        {
          key: 30,
          value: "新",
        },
        {
          key: 31,
          value: "黔",
        },
        {
          key: 32,
          value: "港",
        },
        {
          key: 33,
          value: "澳",
        },
        {
          key: 34,
          value: "台",
        },
        {
          key: 35,
          value: "使",
        },
        {
          key: 36,
          value: "无",
        },
        {
          key: 37,
          value: "领",
        },
        {
          key: 38,
          value: "军",
        },
        {
          key: 39,
          value: "警",
        },
        {
          key: 40,
          value: "临",
        },
        {
          key: 41,
          value: "学",
        },
        {
          key: 42,
          value: "挂",
        },
        {
          key: 43,
          value: "应",
        },
        {
          key: 44,
          value: "急",
        },
        {
          key: 45,
          value: "残",
        },
      ], // 默认省份
    };
  },
  methods: {
    // 保存
    save() {
      let self = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.$axios
            .post("/acb/2.0/bacb/parkBusiParam/save", {
              data: {
                parkId: this.$route.query.parkId,
                ...this.form,
              },
            })
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                // if (res.value) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.getByParkId();
                });
                // this.dialogVisible = false;
                //  }
              } else {
                // this.saveLoading = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 搜索
    getByParkId() {
      // this.loading = true;
      let url = `/acb/2.0/bacb/parkBusiParam/getByParkId/${this.$route.query.parkId}`;
      this.$axios.get(url).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          if (JSON.stringify(res.value) !== "{}") {
            this.form = {
              parkBusiParamId: res.value.parkBusiParamId,
              noPlateEntry: res.value.noPlateEntry,
              monthlyCarRentalEntry: res.value.monthlyCarRentalEntry,
              syncStatus: res.value.syncStatus,
              similarPlateMatch: res.value.similarPlateMatch ?? 1,
              policeCarFree: res.value.policeCarFree,
              defaultProvince: res.value.defaultProvince || "", // 默认省份
            };
          } else {
            this.$refs.form.resetFields();
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    // 同步失败
    updateData() {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/parkBusiParam/sync", {
          data: {
            parkId: this.$route.query.parkId,
            parkBusiParamId: this.form.parkBusiParamId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.getByParkId();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.updateLoading = false;
        });
    },

    timeNumber(event) {
      let value = event.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.replace(/^0/g, "");
      this.form.innerPayFreeLeaveTime = value;
    },

    // 初始化
    init() {
      this.getByParkId();
    },
  },
  beforeDestroy() {
    // sessionStorage.searchData = JSON.stringify(this.formInline);
    // sessionStorage.searchFlag = 1;
  },
  components: {},
  created() {
    // console.log('二级业务参数分析:' + JSON.stringify(this.authority))
    // this.getByParkId();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  padding: 20px 20px 0;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
