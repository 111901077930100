<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Channel_name')">
            <el-input v-model="formInline.parkChannelName" placeholder="请输入通道名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              v-if="authority.button.query"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"> </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="100" v-if="authority.button.view">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="authority.button.view">查看</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <berth-detail ref="berthDetail"></berth-detail>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import berthDetail from "./Detail";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "channeIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "parkChannelName",
          label: this.$t("list.Channel_name"),
          width: "",
        },
        {
          prop: "channelType",
          label: "出入口类型",
          width: "",
          formatter: (row) => {
            switch (row.channelType) {
              case 1:
                return "外场入口";
              case 2:
                return "外场出口";
              case 3:
                return "内场入口";
              case 4:
                return "内场出口";
              case 5:
                return "外场出入口";
              case 6:
                return "内场出入口";
              default:
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        parkChannelName: "",
      },
    };
  },
  methods: {
    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.berthDetail.getDetail(data.parkChannelId);
        this.$refs.berthDetail.open();
      }
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/parkChannel/list", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list || [];
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    berthDetail,
  },
  created() {
    // console.log('通道管理:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
