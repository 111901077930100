<template>
  <div>
    <el-dialog
      title="编辑入场开闸方式"
      :visible.sync="dialogVisible"
      width="500"
      :show-close="false"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" style="padding: 0">
        <el-form-item label="车辆组:" prop="CarGroupIds">
          <el-select
            v-model="form.CarGroupIds"
            placeholder="请选择"
            multiple
            collapse-tags
          >
            <el-option
              :label="value.parkCarGroupName"
              :value="value.parkCarGroupId"
              :key="value.parkCarGroupId"
              v-for="value in carGroupList"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通道名称:" prop="ChannelIds">
          <el-select v-model="form.ChannelIds" placeholder="请选择" multiple collapse-tags>
            <el-option
              :label="value.parkChannelName"
              :value="value.parkChannelId"
              :key="value.parkChannelId"
              v-for="value in channelList"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开闸方式:" prop="openGateType">
          <el-select v-model="form.openGateType" placeholder="请选择">
            <el-option :value="1" label="自动开闸">自动开闸</el-option>
            <el-option :value="2" label="不开闸">不开闸</el-option>
            <el-option v-if="$route.query.parkAccessType != 3" :value="3" label="确认开闸"
              >确认开闸</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间:" required>
          <span style="display: inline-block">
            <el-form-item prop="startTime">
              <el-time-picker
                v-model="form.startTime"
                arrow-control
                placeholder="开始时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                style="width: 150px"
              >
              </el-time-picker>
            </el-form-item>
          </span>
          <span style="display: inline-block; margin: 0 7px">－</span>
          <span style="display: inline-block">
            <el-form-item prop="endTime">
              <el-time-picker
                v-model="form.endTime"
                arrow-control
                placeholder="结束时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
                style="width: 150px"
              >
              </el-time-picker>
            </el-form-item>
          </span>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder=""
            auto-complete="off"
            maxlength="200"
            :rows="4"
            show-word-limit
            style="width: 330px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  data() {
    return {
      dialogVisible: false,
      parkOpenGateId: "",
      form: {
        CarGroupIds: [],
        ChannelIds: [],
        openGateType: "",
        startTime: "",
        endTime: "",
        description: "",
      },
      rules: {
        CarGroupIds: {
          type: "array",
          required: true,
          message: "请选择车辆组",
          trigger: "change",
        },
        ChannelIds: {
          type: "array",
          required: true,
          message: "请选择通道名称",
          trigger: "change",
        },
        openGateType: {
          required: true,
          message: "请选择开闸方式",
          trigger: "change",
        },
        startTime: [
          { type: "string", required: true, message: "请选择开始日期", trigger: "change" },
        ],
        endTime: [{ type: "string", required: true, message: "请选择结束日期", trigger: "change" }],
      },
      // 车辆组
      carGroupList: [
        {
          parkCarGroupName: "白名单",
          parkCarGroupId: "0",
        },
      ],
      // 通道
      channelList: [],
      saveLoading: false,
    };
  },
  methods: {
    // 打开窗口
    open() {
      this.dialogVisible = true;
      this.form.CarGroupIds = [];
      this.form.ChannelIds = [];
      this.form.openGateType = "";
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.description = "";
    },

    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let parms = {};
          parms.parkCarGroupIds = this.form.CarGroupIds?.join(",");
          parms.parkChannelIds = this.form.ChannelIds?.join(",");
          parms.openGateStartTime =
            dateFormat(this.form.startTime, "HH:mm:ss") || this.form.startTime;
          parms.openGateEndTime = dateFormat(this.form.endTime, "HH:mm:ss") || this.form.endTime;
          if (this.compareTime(parms.openGateStartTime, parms.openGateEndTime)) {
            this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false;
          }
          parms.openGateType = this.form.openGateType;
          parms.description = this.form.description;

          // console.log('提交分析:' + JSON.stringify(parms));
          this.$axios
            .post("/acb/2.0/bacb/parkOpenGate/update", {
              data: {
                parkId: this.$route.query.parkId,
                parkOpenGateId: this.parkOpenGateId,
                ...parms,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                setTimeout(() => {
                  this.saveLoading = false;
                  this.$emit("searchData");
                  this.dialogVisible = false;
                }, 2000);
              } else {
                this.saveLoading = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 开始时间、结束时间比较
    compareTime(startTime, endTime) {
      let start = startTime.replace(/-|\s|:|\//g, "");
      let end = endTime.replace(/-|\s|:|\//g, "");
      if (start > end) {
        return true;
      } else {
        return false;
      }
    },

    // 获取详情
    getDetail(id) {
      // console.log('对应ID:' + id)
      this.parkOpenGateId = id;
      this.$route.query.parkAccessType != 3 && this.getCarGroupList();
      this.getchannelList();
      this.getFormInfo();
    },

    // 查询车辆组
    getCarGroupList() {
      this.carGroupList = [];
      let url = "/acb/2.0/bacb/parkCarGroup/parkCarGroupNameList";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.carGroupList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 查询通道
    getchannelList() {
      this.channelList = [];
      let url = "/acb/2.0/bacb/parkChannel/parkChannelNameList";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            channelTypes: "1,3",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.channelList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 获取详情
    getFormInfo() {
      this.$refs.form && this.$refs.form.resetFields();
      this.$axios
        .get("/acb/2.0/bacb/parkOpenGate/getById/" + this.parkOpenGateId, {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.form.CarGroupIds = res.value.parkCarGroupIds?.split(",") || ['0'];
            this.form.ChannelIds = res.value.parkChannelIds?.split(",");
            this.form.openGateType = res.value.openGateType;
            this.form.startTime = res.value.openGateStartTime;
            this.form.endTime = res.value.openGateEndTime;
            this.form.description = res.value.description;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  },

  mounted() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
