<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item label="">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="addBerth"
              v-if="authority.button.add"
              >添加入场开闸流程</el-button
            >
            <span class="textInfo">出场车辆缴费成功后会自动抬杆放行</span>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 99%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"> </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column label="起止时间" width="" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.openGateStartTime }}-{{ scope.row.openGateEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="$route.query.parkAccessType != 3" label="同步状态" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.remarks')" width="" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.edit || authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="b" v-if="authority.button.edit">编辑</el-dropdown-item>
                  <el-dropdown-item command="c" v-if="authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="updateData()"
            :loading="updateLoading"
            :disabled="tableData.length == 0"
            v-if="authority.button.sync && $route.query.parkAccessType != 3"
            >同步本地</el-button
          >
        </div>

        <add-berth ref="addBerth" @searchData="searchData()"></add-berth>
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "gateIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  data() {
    return {
      parkTypeAll: [],
      workStateAll: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "parkCarGroupNames",
          label: this.$t("list.Vehicle_group"),
          width: "",
        },
        {
          prop: "openGateType",
          label: "开闸方式",
          width: "",
          formatter: (row) => {
            switch (row.openGateType) {
              case 1:
                return "自动开闸";
              case 2:
                return "不开闸";
              case 3:
                return "确认开闸";
              default:
            }
          },
        },
        {
          prop: "parkChannelNames",
          label: this.$t("list.Channel_name"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {},
      updateLoading: false,
      addVisible: false,
    };
  },
  methods: {
    // 添加
    addBerth() {
      this.addVisible = true;
      this.$refs.addBerth.open();
    },

    // 删除
    deleteData({ parkOpenGateId }) {
      this.$confirm("确定要将该开闸纪录删除吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/parkOpenGate/delete/" + parkOpenGateId, {
              data: {
                parkOpenGateId: parkOpenGateId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                // if (res.value) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.searchData();
                // }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "b") {
        this.$refs.editBerth.getDetail(data.parkOpenGateId);
        this.$refs.editBerth.open();
      }
      if (cmd == "c") {
        this.deleteData(data);
      }
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/parkOpenGate/list", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value.list || [];
              this.total = res.value.total * 1;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 同步本地
    updateData() {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/parkOpenGate/sync", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.updateLoading = false;
          this.page = 1;
          this.searchData();
        });
    },

    init() {
      // this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    editBerth,
  },
  created() {
    // console.log('开闸方式管理:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
	.textInfo
	  color #888888
	  margin:0 0 0 10px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
.color_1
  color #333333
.color_2
  color #D9001B
</style>
