<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="视频设备名称">
            <el-input
              v-model="formInline.monitorEquipmentName"
              placeholder="请输入设备名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              v-if="authority.button.query"
              icon="el-icon-search"
              :loading="loading"
              @click="
                page = 1;
                searchData();
              "
            >
              查询
            </el-button>
            <el-button
              type="primary"
              v-if="authority.button.add"
              icon="el-icon-plus"
              @click="addBerth"
            >
              添加
            </el-button>
            <el-button
              type="primary"
              plain
              v-if="authority.button.import"
              icon="el-icon-upload"
              @click="openMuli"
            >
              批量导入
            </el-button>
            <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              v-if="authority.button.export"
              @click="exportFile"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!--<el-table-column type="selection" width="55"></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            v-if="authority.button.delete || authority.button.edit"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.edit" command="a">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.delete" command="b"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>

        <!--批量导入-->
        <upload-file
          ref="upload"
          :uploadData="uploadData"
          :uploadUrl="uploadUrl"
          :modelUrl="modelUrl"
          @success="success"
        >
        </upload-file>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
import uploadFile from "@/components/uploadFile/";
import { exportExcelNew } from "@/common/js/public.js";
export default {
  name: "monitorIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selection: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "monitorEquipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "ipAddress",
          label: this.$t("list.IP_address"),
          width: "",
        },
        {
          prop: "ipPort",
          label: this.$t("list.Port_number"),
          width: "",
        },
        {
          prop: "monitorPort",
          label: this.$t("list.video_Port_number"),
          width: "",
        },
        {
          prop: "account",
          label: "用户名",
          width: "",
        },
        {
          prop: "password",
          label: "密码",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_date"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        monitorEquipmentName: "",
      },
      uploadData: {
        parkId: this.$route.query.parkId,
      },
      uploadUrl: "/acb/2.0/bacb/monitorEquipment/batchImport",
      modelUrl: "/acb/2.0/bacb/monitorEquipment/downloadExcelTemplate",
    };
  },
  methods: {
    addBerth() {
      this.$refs.addBerth.open();
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open();
      }
      if (cmd == "b") {
        this.dataDelete(data.monitorEquipmentId);
      }
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    selectionChange(selection) {
      this.selection = selection;
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/monitorEquipment/listMonitorEquipment", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // 删除
    dataDelete(id) {
      this.$confirm("将删除此条监控记录,是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/bacb/monitorEquipment/delete/" + id;
          this.$axios.post(url).then((res) => {
            if (res.state == 0) {
              this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              }).then(() => {
                this.page = 1;
                this.searchData();
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 导出
    exportFile() {
      exportExcelNew("/acb/2.0/bacb/monitorEquipment/export", {
        parkId: this.$route.query.parkId,
        monitorEquipmentName: this.formInline.monitorEquipmentName,
      });
    },

    // 批量导入
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },

    // 批量导入成功
    success() {
      this.searchData();
    },

    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    uploadFile,
    addBerth,
    editBerth,
  },
  created() {
    // console.log('视频监控管理:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
