var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 14 } }, [
              _c("h2", { staticClass: "title" }, [
                _c("div", { staticClass: "title_icon" }),
                _vm._v(
                  " " + _vm._s(_vm.$route.query.slaveRelationName) + "配置 "
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 1, offset: 9 } }, [
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "tab-wrap",
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName2,
              callback: function ($$v) {
                _vm.activeName2 = $$v
              },
              expression: "activeName2",
            },
          },
          [
            _vm.parkAccessType == 3
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "业务参数配置", name: "first" } },
                  [
                    _c("parameters", {
                      ref: "parameters",
                      attrs: { authority: _vm.authority.tabs["parameters"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["videMonitor"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "视频监控管理", name: "second" } },
                  [
                    _c("vide-monitor", {
                      ref: "videMonitor",
                      attrs: { authority: _vm.authority.tabs["videMonitor"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["channel"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "通道配置", name: "third" } },
                  [
                    _c("channel", {
                      ref: "channel",
                      attrs: { authority: _vm.authority.tabs["channel"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["gate"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "开闸方式配置", name: "fourth" } },
                  [
                    _c("gate", {
                      ref: "gate",
                      attrs: { authority: _vm.authority.tabs["gate"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            0
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "计费规则管理", name: "five" } },
                  [
                    _c("calculate", {
                      ref: "calculate",
                      attrs: { authority: _vm.authority.tabs["calculate"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs[
              _vm.parkAccessType == 3 ? "lbParkCarMange" : "parkCarMange"
            ] && _vm.parkAccessType == 3
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "车辆管理", name: "six" } },
                  [
                    _c("cars-manage", {
                      ref: "cars",
                      attrs: {
                        authority:
                          _vm.authority.tabs[
                            _vm.parkAccessType == 3
                              ? "lbParkCarMange"
                              : "parkCarMange"
                          ],
                        details: _vm.details,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }